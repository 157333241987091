import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Content from "../../components/content"
import Footer from "../../components/footer"
import SEO from "../../components/seo"
import NewsAll from '../../components/news-all'
import Img from "gatsby-image"

const ArchivePage = ({data}) => {
    return (
        <Layout>
            <SEO title="News Archive" />
                    <div className="title-section">
                        <h1 className="title is-size-1">News Archive</h1>
                    </div>

                    <div style={{marginTop: '4rem'}} className="news-container">
                        <NewsAll posts={data.graphAPI.blogPosts} />
                    </div>
        </Layout>
    )
}

export default ArchivePage

export const archivePageQuery = graphql`
    query archivePage {
        graphAPI {
            blogPosts(sort: "publishedAt:desc") {
                id
                title
                description
                publishedAt
                externalLink
                heroImage {
                    id
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 2048){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        } 
    }
`


